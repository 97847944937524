import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationStateManagementService {
  notificationCount = 0;
  notificationEvent = new BehaviorSubject(null);
  constructor() { }

  setNoticationCount(count) {
    this.notificationCount = count;
    this.notificationEvent.next(count);
  }

  zeroNotification() {
    this.notificationCount = 0;
    this.notificationEvent.next(0);
  }
}
