import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { Dbkey } from './db-key';
import { EncoderService } from './encoder.service';
import { LocalStoreManager } from './local-store-manager.service';
import { ConfigurationService } from './configuration.service';
import { UserLogin } from '../models/user-login.model';
import { ResponseModel } from '../models/response.model';
import {AlertService, DialogType, MessageSeverity} from '../services/alert.service';

@Injectable({
  providedIn: 'root',
})
export class EndpointFactoryService {
  protected subscription: Subscription;
  private _accessToken: string;
  private taskPauser: Subject<any>;
  private isRefreshingLogin: boolean;
  private readonly _loginUrl: string = '/connect/token';
  private readonly _verifyloginUrl: string = '/api/v1/login/verify';
  private readonly forgetPasswordUrl =
    this.configurations.baseUrl + '/api/v1/user/forgetPassword/email';
  private get loginUrl() {
    return this.configurations.baseUrl + this._loginUrl;
  }
  get accessToken(): string {
    return this.localStorage.getData(Dbkey.ACCESS_TOKEN);
  }

  constructor(
    protected http: HttpClient,
    protected alertService : AlertService,
    protected configurations: ConfigurationService,
    private injector: Injector,
    protected encoderService: EncoderService,
    private localStorage: LocalStoreManager
  ) {}

  getForgetPasswordEndpoint(email: string): Observable<ResponseModel> {
    return this.http.post<ResponseModel>(
      this.forgetPasswordUrl,
      {
        email,
      },
      { headers: this.getRequestHeaders().headers }
    );
  }
  getLoginEndpoint<T>(userLogin: UserLogin): Observable<T> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    // const header = new HttpHeaders({ 'Content-Type': 'application/json' });

    const params = new HttpParams({
      encoder: this.encoderService,
    })
      .append('username', userLogin.email)
      .append('password', userLogin.password)
      .append('code', userLogin.code)
      .append('grant_type', 'password')
      .append('granttype', 'password')
      .append('scope', 'openid email profile offline_access roles');
    const requestBody = params.toString();
    return this.http.post<T>(this.loginUrl, requestBody, { headers: header });
  }

  getRefreshLoginEndpoint<T>(token: string): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const params = new HttpParams()
      .append('refresh_token', token)
      .append('grant_type', 'refresh_token')
      .append('granttype', 'refresh_token')
      .append('scope', 'openid email profile offline_access roles');
    const requestBody = params.toString();

    return this.http.post<T>(this.loginUrl, requestBody, { headers: header });
  }
  protected getReportRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.accessToken,
    });

    return { headers: headers };
  }

  protected getRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.accessToken,
      'Content-Type': 'application/json',
      Accept: `application/vnd.iman.v1+json, application/json, text/plain, */*`,
      'App-Version': '1',
    });

    return { headers: headers };
  }

  public resumeTasks(continueOp: boolean) {
    setTimeout(() => {
      if (this.taskPauser) {
        this.taskPauser.next(continueOp);
        this.taskPauser.complete();
        this.taskPauser = null;
      }
    });
  }

  protected getAuthHeader(
    includeJsonContentType?: boolean
  ): { headers: HttpHeaders | { [header: string]: string | string[] } } {
    let headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.accessToken,
    });

    if (includeJsonContentType) {
      headers = headers.append('Content-Type', 'application/json');
    }

    headers = headers.append(
      'Accept',
      `application/vnd.iman.v1+json, application/json, text/plain, */*`
    );
    headers = headers.append('App-Version', ConfigurationService.appVersion);
    return { headers: headers };
  }
}
