import {Component, Inject, OnInit} from '@angular/core';
import {AlertDialog, DialogType} from '../../services/alert.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  labelOk: string;
  labelNo: string;
  content: string;
  type: DialogType;
  OkClick: any;
  NoClick: () => void;
  html: string;
  constructor(private modalService: NgbModal) {
  }

  initDialog(data: AlertDialog) {
    this.labelOk = data.okLabel;
    this.labelNo = data.cancelLabel;
    this.type = data.type;
    this.content = data.message;
    this.OkClick = data.okCallback;
    this.NoClick = data.cancelCallback;
    this.html = data.html;
    this.fireSawal();
  }

  ngOnInit() {

  }
  confirmSawal() {
    Swal.fire({
      title: this.content,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: this.labelOk,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.OkClick();
      } else if (result.isDenied) {
        this.NoClick();
      }
      this.modalService.dismissAll();
    });
  }
  fireSawal() {
    switch (this.type) {
      case DialogType.alert:
        return 'Alert';
      case DialogType.confirm:
        this.confirmSawal();
        break;
      case DialogType.prompt:
        this.promptSwal();
        break;
      case DialogType.HtmlPrompt:
        this.htmlSwal();
        break;
      default:
        break;
    }
  }
  htmlSwal() {
    Swal.fire({

      html: this.html,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText:
        '<i class="fa fa-thumbs-up"></i> Verify',
      confirmButtonAriaLabel: 'Thumbs up, Ok',
      cancelButtonText:
        '<i class="fa fa-thumbs-down"></i> Cancel',
      cancelButtonAriaLabel: 'Cancel'
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.OkClick();
      } else if (result.isDenied) {
        this.NoClick();
      }
      this.modalService.dismissAll();
    });
  }
  promptSwal() {
    Swal.fire({
      titleText: this.content,
      allowEscapeKey: false,
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      },

    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.OkClick();
      } else if (result.isDenied) {
        this.NoClick();
      }
      this.modalService.dismissAll();
    });
  }

  getType(): string {
    switch (this.type) {
      case DialogType.alert:
        return 'Alert';
      case DialogType.confirm:
        return 'Confirm';
      case DialogType.prompt:
        return 'Prompt';
      default:
        return '';
    }
  }

  close() {
    if (this.NoClick) {
      this.NoClick();
    }
  }
}
