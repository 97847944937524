import { Injectable } from '@angular/core';
import {LoginEndpointService} from './login-endpoint.service';
import {Observable} from 'rxjs';
import {ChangePassword} from '../../../models/change-password';
import {ResponseModel} from '../../../models/response.model';
import {UserLogin} from '../../../models/user-login.model';
import {LoginResponse} from '../../../models/login-response';
import { map } from 'rxjs/operators';
import { MessagingService } from 'src/app/services/notification.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(protected loginEndpoint: LoginEndpointService, protected messagingService: MessagingService) {

  }

  login(userLogin: UserLogin): Observable<LoginResponse> {
    return this.loginEndpoint.getLoginEndpoint(userLogin);
  }
  refreshToken(token: string): Observable<LoginResponse> {
    return this.loginEndpoint.getRefreshLoginEndpoint<LoginResponse>(token);
  }

  changePassword(changePassword: ChangePassword) {
    return this.loginEndpoint.getChangePasswordEndpoint(changePassword).pipe(map((response) => <ResponseModel>response));
  }
  forgetPassword(email: string) {
    return this.loginEndpoint.getForgetPasswordEndpoint(email).pipe(map((response) => <ResponseModel>response));
  }

  
  postFcmId() {
    this.messagingService.token.subscribe((token) => {
    //  console.log(token);
      // this.loginEndpoint.updateFcm(token).subscribe(
      //   (res) => {
      // //    console.log(res);
      //   }
      // );
    },
    (err) => {
      console.error('Unable to get permission to notify.', err);
    });
  }
}
