import { Component, OnInit, Renderer2, ViewChild, ElementRef, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { filter } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { ScreenTitle } from '../../constants/title';
import { SignalRService } from 'src/app/services/signal-r.service';
import { AlertService } from 'src/app/services/alert.service';
import { NotificationServiceService } from 'src/app/pages/dashboard/notifications/service/notification-service.service';
const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};
@Component({
  selector: 'app-navbar',
  templateUrl: 'navbar.component.html'
})
export class NavbarComponent implements OnInit {
  @ViewChild('notificationAudio', {
    static: true
  })
  notificationAudio: ElementRef;
  fullScreen = false;
  public isCollapsed = false;
  private listTitles: any[];
  location: Location;
  private nativeElement: Node;
  private toggleButton;
  private sidebarVisible: boolean;
  private _router: Subscription;
  public open = false;
  elem;
  @ViewChild('navbar-cmp', { static: false }) button;
  constructor(location: Location, private renderer: Renderer2, private element: ElementRef, private router: Router,
    private signalRService : SignalRService,
    private alertService : AlertService,
    private notificationService: NotificationServiceService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }
  ngOnInit() {
    this.elem = document.documentElement;
    this.listTitles = SidebarComponent.Routes.filter(listTitle => listTitle);
    const navbar: HTMLElement = this.element.nativeElement;
    const body = document.getElementsByTagName('body')[0];
    const elements: Element[] = Array.from(document.getElementsByClassName('navbar-toggler'));
    this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      const $layer = document.getElementsByClassName('close-layer')[0];
      if ($layer) {
        $layer.remove();
      }
    });
    this.init()
  }
  init()
  {
    this.signalRService.getNewNotificationEvent().subscribe(res=>{
      this.getNotifications();
      this.playNotificationAudio();
      this.alertService.showSuccessMessage(res.message)
  })
  this.signalRService.getNewSaleEvent().subscribe(res=>{
    this.playNotificationAudio();
    this.alertService.showSuccessMessage(res.message)
})
  }
  getNotifications()
  {
    this.notificationService
    .getNotifications(1, 10, '', '', '', 0)
    .subscribe((results) => {
    });
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add('sidebar-mini');
        misc.sidebar_mini_active = true;
      }, 300);
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);
    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  isMobileMenu() {
    if (window.outerWidth < 991) {
      return false;
    }
    return true;
  }
  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    setTimeout(function () {
      //    toggleButton.classList.add('toggled');
    }, 500);
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    html.classList.add('nav-open');
    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    // this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = '';
      }, 500);
    }
  }
  sidebarToggle() {
    // var toggleButton = this.toggleButton;
    // var body = document.getElementsByTagName('body')[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }
  ////////////////////////////////////////////////
  getTitle(): string {
    let titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    for (let item = 0; item < this.listTitles.length; item++) {
      const parent = this.listTitles[item];
      if (parent.path === titlee) {
        return parent.title;
      } else if (parent.children) {
        for (let current = 0; current < parent.children.length; current++) {
          if (parent.path + parent.children[current].path === titlee) {
            return parent.children[current].title;
          }
        }
      }
    }
    return '';
  }
  getPath() {
    return this.location.prepareExternalUrl(this.location.path());
  }
  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (this.isCollapsed) {
      navbar.classList.remove('navbar-transparent');
      navbar.classList.add('bg-white');
    } else {
      navbar.classList.add('navbar-transparent');
      navbar.classList.remove('bg-white');
    }
  }
  openFullscreen() {
    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }
    this.fullScreen = true;
  }
  /* Close fullscreen */
  closeFullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
    this.fullScreen = false;
  }
  showChart() {
    const title = this.getTitle().toUpperCase();
    if (title === ScreenTitle.Dashboard) {
      return true;
    } else {
      return false;
    }
  }
  playNotificationAudio() {
    (this.notificationAudio.nativeElement as HTMLAudioElement).play();
  }
  stopNotificationAudio() {
    (this.notificationAudio.nativeElement as HTMLAudioElement).pause();
  }
}