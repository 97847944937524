import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { RoleNames } from '../../constants/role-names';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SignalRService } from '../../services/signal-r.service';
import { SoundService } from '../../services/sound.service';
import {NotificationServiceService} from '../../pages/dashboard/notifications/service/notification-service.service';
import { NotificationStateManagementService } from 'src/app/services/notification-state-Management.service';
import { MessagingService } from '../../services/notification.service';
const misc: any = {
  sidebar_mini_active: true,
};
// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  isNotification?: boolean;
  // icon: string;
  children?: ChildrenItems[];
}
export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
}
// Menu Items
export const ADMINUPPERROUTES: RouteInfo[] = [
  {
    path: '/dashboard/profile',
    title: 'Profile',
    type: 'link',
    icontype: 'fas fa fa-user',
  },
];
export const MERCHUPPERROUTES: RouteInfo[] = [
  {
    path: '/dashboard/profile-merch',
    title: 'Profile',
    type: 'link',
    icontype: '',
  }
  // {
  //   path: '/dashboard/merchant-subscription',
  //   title: 'Subscription Plan',
  //   type: 'link',
  //   icontype: 'fas fa fa-user',
  // },
];
export const NOTVARIFIEDMERCHUPPERROUTES: RouteInfo[] = [
  {
    path: '/dashboard/profile-merch',
    title: 'Profile',
    type: 'link',
    icontype: '',
  }
];
export const ADMINROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home',
    title: 'Dashboard',
    type: 'link',
    icontype: 'fi-ecommerce-box-1',
  },
  {
    path: '/dashboard/notifications',
    title: 'Notifications',
    type: 'link',
    icontype: 'fi-creative-alarm',
    isNotification: true
  },
  {
    path: '/dashboard/membership',
    title: 'Membership',
    type: 'link',
    icontype: 'fi-ecommerce-box-1',
  },
  {
    path: '/dashboard',
    title: 'User',
    type: 'sub',
    icontype: 'fi-interface-user-2',
    collapse: 'Settings',
    children: [
      {
        path: '/customer',
        title: 'Customer',
        type: 'link',
        ab: 'fas fa-hand-holding-usd',
      },
      {
        path: '/merchant',
        title: 'Merchant',
        type: 'link',
        ab: 'fas fa-people-carry',
      },
    ],
  },
  // {
  //   path: '/dashboard/benifits',
  //   title: 'Benefits',
  //   type: 'link',
  //   icontype: 'fi-interface-sticker',
  // },
  {
    path: '/dashboard/orders',
    title: 'Orders',
    type: 'link',
    icontype: 'fi-interface-list-4',
  },
  {
    path: '/dashboard/transactions',
    title: 'Transaction',
    type: 'link',
    icontype: 'fi-ecommerce-money',
  },
  //  {path: '/dashboard/shop', title: 'Shop', type: 'link', icontype: 'fas fa-store-alt'},
  //  {path: '/dashboard/shop-tables', title: 'Shop Tables', type: 'link', icontype: 'fas fa-table'},
  //  {path: '/dashboard/menu-catagory', title: 'Menu catagory', type: 'link', icontype: 'fas fa-bars'},
  // {path: '/dashboard/menu-items', title: 'Menu items', type: 'link', icontype: 'fas fa-bars'},
  // {path: '/dashboard/menu-invoice', title: 'Menu invoice', type: 'link', icontype: 'fas fa-file-invoice'},
  //  {path: '/dashboard/reviews', title: 'Reviews', type: 'link', icontype: 'fas fa fa-users'},
  //  {path: '/dashboard/email-marketing', title: 'Email', type: 'link', icontype: 'fas fa-envelope-open'},
  //  {path: '/dashboard/pay-links', title: 'Pay links', type: 'link', icontype: 'fas fa-link'},
  //  {path: '/dashboard/card', title: 'card', type: 'link', icontype: 'far fa-credit-card'},
  //  {path: '/dashboard/faqs', title: 'Faqs', type: 'link', icontype: 'fas fa-question'},
  {
    path: '/logout',
    title: 'Logout',
    type: 'link',
    icontype: 'fi-interface-logout',
  },
];
export const WHENUNVERIFIEDROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home-merch',
    title: 'Dashboard',
    type: 'link',
    icontype: 'fi-ecommerce-box-1',
  },
  {
    path: '/dashboard/notifications',
    title: 'Notifications',
    type: 'link',
    isNotification: true,
    icontype: 'fi-creative-alarm',
  },
  {
    path: '/logout',
    title: 'Logout',
    type: 'link',
    icontype: 'fi-interface-logout',
  }
];
export const MERCHANTROUTES: RouteInfo[] = [
  {
    path: '/dashboard/home-merch',
    title: 'Dashboard',
    type: 'link',
    icontype: 'fi-ecommerce-box-1',
  },
  {
    path: '/dashboard/notifications',
    title: 'Notifications',
    type: 'link',
    icontype: 'fi-creative-alarm',
    isNotification: true
  },
  {
    path: '/dashboard/customer',
    title: 'Customer',
    type: 'link',
    icontype: 'fi-interface-stick-man-2',
  },{
    path: '/dashboard',
    title: 'Category',
    type: 'sub',
    icontype: 'fi-creative-clipboard-1',
    collapse: 'Settings',
    children: [
      {
        path: '/catagory',
        title: 'Main Category',
        type: 'link',
        ab: 'fas fa-people-carry',
      },
      {
        path: '/subcatagory',
        title: 'Sub-Category',
        type: 'link',
        ab: 'fas fa-people-carry',
      },
    ],
  },
  {
    path: '/dashboard/products',
    title: 'Products',
    type: 'link',
    icontype: 'fi-tech-server-4',
  },
  {
    path: '/dashboard/benifits',
    title: 'Benefits',
    type: 'link',
    icontype: 'fi-interface-sticker',
  },
  {
    path: '/dashboard/orders',
    title: 'Orders',
    type: 'link',
    icontype: 'fi-interface-list-4',
  },
  {
    path: '/dashboard/kiosk',
    title: 'Kiosk',
    type: 'link',
    icontype: 'fi-tech-ereader',
  },
  {
    path: '/dashboard/transactions',
    title: 'Transactions',
    type: 'link',
    icontype: 'fi-ecommerce-money',
  },
  // {path: '/dashboard/menu-invoice', title: 'Menu invoice', type: 'link', icontype: 'fas fa-file-invoice'},
  {
    path: '/logout',
    title: 'Logout',
    type: 'link',
    icontype: 'fi-interface-logout',
  },
];
@Component({
  selector: 'app-sidebar',
  templateUrl: 'sidebar.component.html',
  styleUrls: ['sidebar.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit, AfterViewInit {
  public static Routes: RouteInfo[];
  currentRoutes: RouteInfo[];
  upperMenuRoutes: RouteInfo[];
  notificationCount = 0;
  getNotificationCount() {
    this.notificationService.getNotificationCount().subscribe( res => {
      this.notificationState.setNoticationCount(res.count);
    });
  }
  viewNotification() {
  }
  constructor(
    private authService: AuthService,
    private matIconReg: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private notificationService: NotificationServiceService,
    private notificationState: NotificationStateManagementService,
    private messagingService: MessagingService
  ) {}
  isNotMobileMenu() {
    return window.outerWidth <= 991;
  }
  ngOnInit() {
    this.notificationState.notificationEvent.subscribe(res => {
      this.notificationCount = this.notificationState.notificationCount;
    });
    this.notificationCount = this.notificationState.notificationCount;
    this.getNotificationCount();
    if (this.authService.isRole(RoleNames.Administrator)) {
      this.currentRoutes = ADMINROUTES.filter(() => true);
      this.upperMenuRoutes = ADMINUPPERROUTES.filter(() => true);
      for (let i = 0; i < ADMINROUTES.length; i++) {
        this.matIconReg.addSvgIcon(
          this.currentRoutes[i].title,
          this.domSanitizer.bypassSecurityTrustResourceUrl(
            this.currentRoutes[i].icontype
          )
        );
      }
      SidebarComponent.Routes = this.upperMenuRoutes.filter(() => true);
    }
    if (this.authService.isRole(RoleNames.Merchant)) {
      if (this.authService.isVerified) {
        this.upperMenuRoutes = MERCHUPPERROUTES.filter(() => true);
        this.currentRoutes = MERCHANTROUTES.filter(() => true);
        SidebarComponent.Routes = this.currentRoutes.filter(() => true);
        for (let i = 0; i < MERCHANTROUTES.length; i++) {
          this.matIconReg.addSvgIcon(
            this.currentRoutes[i].title,
            this.domSanitizer.bypassSecurityTrustResourceUrl(
              this.currentRoutes[i].icontype
            )
          );
        }
      } else {
        this.upperMenuRoutes = NOTVARIFIEDMERCHUPPERROUTES.filter(() => true);
        this.currentRoutes = WHENUNVERIFIEDROUTES.filter(() => true);
        SidebarComponent.Routes = this.upperMenuRoutes.filter(() => true);
      }
    }
    this.messagingService.currentMessage.subscribe((result) => {
      this.notificationService.getNotificationCount().subscribe(  res => {
        this.notificationCount = res.count;
      }
      );
    });
  }
  ngAfterViewInit() {
    // this.maximizeProfileDropdown();
  }
  getProfileImage(): string {
    return this.authService.currentUser.image;
  }
  getProfileName(): string {
    return this.authService.currentUser.fullName[0];
  }
  maximizeProfileDropdown() {
    if (this.authService.isVerified) {
      const button = document.getElementById('profileDropDown');
      button.click();
    }
  }
  minimizeSidebar() {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove('sidebar-mini');
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add('sidebar-mini');
      misc.sidebar_mini_active = true;
    }
    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event('resize'));
    }, 180);
    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
}