import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AlertService} from '../../services/alert.service';
import {CustomValidator} from '../../customValidator/custom-validator';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  isInitialized = false;
  saveEvent: (password: string, confirmPassword: string) => void;
  passwordControl: FormControl;
  confirmPasswordControl: FormControl;
  passwordGroup: FormGroup;

  constructor(private alertService: AlertService, private ngbActiveModal: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  initForm() {
    this.passwordControl = new FormControl('', [Validators.required, CustomValidator.Password()]);
    this.confirmPasswordControl = new FormControl('', [Validators.required, CustomValidator.PasswordMatch(this.passwordControl)]);
    this.passwordGroup = new FormGroup({
      password: this.passwordControl,
      confirmPassword: this.confirmPasswordControl,
    });
    this.isInitialized = true;
  }


  save() {
    if (this.passwordGroup.valid) {
      if (this.saveEvent) {
        this.saveEvent(this.passwordControl.value, this.confirmPasswordControl.value);
      }
    } else {
      this.alertService.showErrorMessage('Please fill all the required values');
    }

  }

  close() {
    this.ngbActiveModal.close();
  }
}
