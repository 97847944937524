import {SidebarComponent} from './sidebar/sidebar.component';
import {NavbarComponent} from './navbar/navbar.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {SearchBoxComponent} from './search-box/search-box.component';
import {FormErrorComponent} from './form-error/form-error.component';
import {NgModule} from '@angular/core';
import {SuccessDialogComponent} from './success-dialog/success-dialog.component';
import {ChangePasswordDialogComponent} from './change-password-dialog/change-password-dialog.component';
import {ImageComponent} from './image/image.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BackButtonComponent } from './back-button/back-button.component';
import {MatIconModule } from '@angular/material/icon';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { from } from 'rxjs';
import {QRCodeModule} from 'angularx-qrcode';
import {HearderChartComponent} from './hearder-chart/hearder-chart.component';
import {ImageCropperComponent} from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ChartsModule } from 'ng2-charts';
import {NotificationPanelComponent} from './notification-panel/notification-panel.component';
import {CustomDateComponent} from './customer-date-picker/custome-date-picker.component';
@NgModule({
  declarations: [SidebarComponent, CustomDateComponent, NotificationPanelComponent, SearchBoxComponent, PaginatorComponent, NavbarComponent, FormErrorComponent, SuccessDialogComponent, ChangePasswordDialogComponent, ImageComponent, BackButtonComponent, ImageCropperComponent, HearderChartComponent],
    exports: [SidebarComponent, SearchBoxComponent, CustomDateComponent, NotificationPanelComponent, NavbarComponent, FormErrorComponent, SuccessDialogComponent, ChangePasswordDialogComponent, ImageComponent, BackButtonComponent],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        PerfectScrollbarModule,
        QRCodeModule,
        ImageCropperModule,
        ChartsModule
    ]
})
export class SharedModule {
}
