import { Injectable } from '@angular/core';
import { EndpointFactoryService } from '../../../../services/endpoint-factory.service';
import { ResponseModel } from '../../../../models/response.model';
import { HttpParams } from '@angular/common/http';
import { Product } from '../../../../models/product';
import { map } from 'rxjs/operators';
import {Notification} from '../../../../models/notification';
@Injectable({
  providedIn: 'root'
})
export class NotificationServiceService extends EndpointFactoryService  {

  private baseUrl = this.configurations.baseUrl + '/api/v1/Notification';
  private notificationCountUrl = this.baseUrl + '/unread';
  private readNotificationByIdUrl = this.baseUrl + '/read';
  private readNotificationAllUrl = this.baseUrl + '/readAll';




  readAllNotifications() {
    return this.http
      .put(this.readNotificationAllUrl, null, {
        headers: this.getRequestHeaders().headers,
      })
      .pipe(map((response) => <ResponseModel>response));
  }
  readNotificationById(element: Notification) {
    return this.http.put(this.readNotificationByIdUrl + '/' + element.id, null, {headers: this.getRequestHeaders().headers}).pipe(map(res => <ResponseModel>res));
  }
  getNotificationCount() {
    return this.http.get(this.notificationCountUrl, {headers: this.getRequestHeaders().headers}).pipe(map(res => <ResponseModel>res));
  }


  getNotifications(
    page: number,
    pageSize: number,
    search: string,
    orderBy: string,
    direction: string,
    notificationType: number
  ) {
    const params = new HttpParams()
      .set('page', page + '')
      .set('pageSize', pageSize + '')
      .set('search', search + '')
      .set('isDescending', direction === 'desc' ? 'true' : 'false')
      .set('orderBy', orderBy + '').set('NotificationType', notificationType + '');
    return this.http
      .get(this.baseUrl, {
        headers: this.getRequestHeaders().headers,
        params: params,
      })
      .pipe(map((response) => <ResponseModel>response));
  }



}
