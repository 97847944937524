// ====================================================


// ====================================================

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private messages = new Subject<AlertMessage>();
  private stickyMessages = new Subject<AlertMessage>();
  private dialogs = new Subject<AlertDialog>();
  private _isLoading = false;
  private loadingMessageId: any;


  showDialog(message: string);
  showDialog(message: string, type: DialogType, okCallback: (val?: any) => any);
  // tslint:disable-next-line:unified-signatures
  showDialog(message: string, type: DialogType, okCallback: (val?: any) => any, html: string);
  showDialog(message: string, type?: DialogType, okCallback?: (val?: any) => any, html?: string, cancelCallback?: () => any, okLabel?: string,
             cancelLabel?: string, defaultValue?: string) {

    if (!type) {
      type = DialogType.alert;
    }
    if (!cancelCallback) {
      cancelCallback = () => {
        this.closeDialog();
      };
    }

    this.dialogs.next({
      message: message,
      type: type,
      okCallback: okCallback,
      cancelCallback: cancelCallback,
      okLabel: okLabel,
      cancelLabel: cancelLabel,
      defaultValue: defaultValue,
      html: html,
    });
  }


  showDialogWithLabel(message: string, type?: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel: string = 'Ok',
  cancelLabel: string = 'Cancel', defaultValue?: string, html?: string) {
if (!type) {
type = DialogType.alert;
}
if (!cancelCallback) {
cancelCallback = () => {
this.closeDialog();
};
}

this.dialogs.next({
  message: message,
  type: type,
  okCallback: okCallback,
  cancelCallback: cancelCallback,
  okLabel: okLabel,
  cancelLabel: cancelLabel,
  defaultValue: defaultValue,
  html: html
});
}

  // This is a test method to check height


  showMessage(summary: string, detail: string, severity: MessageSeverity) {
    this.showMessageHelper(summary, detail, severity, false);
  }

  showErrorMessage(detail: string) {
    this.showMessageHelper('Error', detail, MessageSeverity.error, false);
  }

  showWarningMessage(detail: string) {
    this.showMessageHelper('Warning', detail, MessageSeverity.error, false);
  }

  showSuccessMessage(detail: string) {
    this.showMessageHelper('Success', detail, MessageSeverity.success, false);
  }


  showStickyMessage(summary: string, detail: string, severity: MessageSeverity) {

    if (!severity) {
      severity = MessageSeverity.default;
    }
    this.showMessageHelper(summary, detail, severity, true);
  }


  private showMessageHelper(summary: string, detail: string, severity: MessageSeverity, isSticky: boolean) {

    if (isSticky) {
      this.stickyMessages.next({severity: severity, summary: summary, detail: detail});
    } else {
      this.messages.next({severity: severity, summary: summary, detail: detail});
    }
  }


  startLoadingMessage(message = 'Processing...', caption = '') {
    this._isLoading = true;
    clearTimeout(this.loadingMessageId);

    this.loadingMessageId = setTimeout(() => {
      this.showStickyMessage(caption, message, MessageSeverity.wait);
    }, 1000);
  }

  stopLoadingMessage() {
    this._isLoading = false;
    clearTimeout(this.loadingMessageId);
    this.resetStickyMessage();
  }

  closeDialog() {
    this.dialogs.next();
  }


  logDebug(msg) {
    console.debug(msg);
  }

  logError(msg) {
    console.error(msg);
  }

  logInfo(msg) {
    console.info(msg);
  }

  logMessage(msg) {
    console.log(msg);
  }

  logTrace(msg) {
    console.trace(msg);
  }

  logWarning(msg) {
    console.warn(msg);
  }


  resetStickyMessage() {
    this.stickyMessages.next();
  }


  getDialogEvent(): Observable<AlertDialog> {
    return this.dialogs.asObservable();
  }


  getMessageEvent(): Observable<AlertMessage> {
    return this.messages.asObservable();
  }

  getStickyMessageEvent(): Observable<AlertMessage> {
    return this.stickyMessages.asObservable();
  }


  get isLoadingInProgress(): boolean {
    return this._isLoading;
  }
}


// ******************** Dialog ********************//
export class AlertDialog {
  constructor(public message: string, public type: DialogType, public okCallback: (val?: any) => any, public cancelCallback: () => any,
              public defaultValue: string, public okLabel: string, public cancelLabel: string, public html: string) {

  }
}

export enum DialogType {
  alert,
  confirm,
  prompt,
  HtmlPrompt
}

// ******************** End ********************//


// ******************** Growls ********************//
export class AlertMessage {
  constructor(public severity: MessageSeverity, public summary: string, public detail: string) {
  }
}

export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn,
  wait
}

// ******************** End ********************//
