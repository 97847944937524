/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {hmrBootstrap} from './hmr';
import {hmr, WebpackModule} from '@ngxs/hmr-plugin';
import './app/extensions/array-extension';

declare const module: WebpackModule;
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

if (environment.hmr) {
  // if (module['hot']) {
  //   hmrBootstrap(module, bootstrap);
  // } else {
  //   console.error('HMR is not enabled for webpack-dev-server!');
  //   console.log('Are you using the --hmr flag for ng serve?');
  // }
  hmr(module, bootstrap).catch(err => console.log(err));
} else {
  bootstrap().catch(err => console.log(err));
}
