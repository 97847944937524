export class AdminDashboard {
  public totalCustomers: number;
  public totalMerchants: number;
  public totalProducts: number;
  public totalOrders: number;
  public labels: string[];
  public dailyOrdersCount: number[];
  public dailyOrders: number[];
  
}
