import { Injectable } from '@angular/core';
import {EndpointFactoryService} from '../../../services/endpoint-factory.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AdminDashboard} from '../../../models/admin-dashboard';
import {ResponseModel} from '../../../models/response.model';
import {VendorDashboard} from '../../../models/vendor-dashboard';
@Injectable({
  providedIn: 'root'
})
export class ChartDataService extends EndpointFactoryService {
  private readonly _getVendorDashboardUrl: string = this.configurations.baseUrl + '/api/v1/Dashboard/merchant';
  private readonly _getAdminDashboardUrl: string = this.configurations.baseUrl + '/api/v1/Dashboard/admin';

  getVendorData(): Observable<VendorDashboard> {
    return this.http.get(this._getVendorDashboardUrl, {headers: this.getRequestHeaders().headers})
      .pipe(map((response) => <VendorDashboard>response));
  }
  getAdminData(): Observable<AdminDashboard> {
    return this.http.get(this._getAdminDashboardUrl, {headers: this.getRequestHeaders().headers})
      .pipe(map((response) => <AdminDashboard>response));
  }
}
