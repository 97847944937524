import { Component, OnInit } from '@angular/core';
import * as Chart from 'chart.js';
import {AdminDashboard} from '../../models/admin-dashboard';
import { VendorDashboard } from '../../models/vendor-dashboard';
import {ChartDataService} from './service/header-chart.service';
import { AuthService } from '../../services/auth.service';
import { RoleNames } from '../../constants/role-names';

@Component({
  selector: 'app-hearder-chart',
  templateUrl: './hearder-chart.component.html',
  styleUrls: ['./hearder-chart.component.scss']
})
export class HearderChartComponent implements OnInit {
  vendorDashboard: VendorDashboard = new VendorDashboard();
  adminDashboard: AdminDashboard = new AdminDashboard();
  public gradientStroke;
  public chartColor = '#FFFFFF';
  public canvas: any;
  public ctx;
  public gradientFill;
  public gradientChartOptionsConfigurationWithNumbersAndGrid: any;
  public myChart: any;
  constructor(private chartDataService: ChartDataService, private authService: AuthService) { }

  loadData() {
    if (this.authService.isRole(RoleNames.Administrator)) {
      this.chartDataService.getAdminData().subscribe(result => {
        this.adminDashboard = result;
        this.getChartData();

      });
    } else {
      this.chartDataService.getVendorData().subscribe(result => {

        this.vendorDashboard = result;
        this.getChartData();
      });
    }
  }

  ngOnInit(): void {
    this.loadData();
  }

  getChartData() {
    let stepSize = 10;
    const max = this.authService.isRole(RoleNames.Administrator) ? this.adminDashboard.dailyOrders.sort() : this.vendorDashboard.dailyOrders.sort();
    if (max[max.length - 1] > stepSize) {
      stepSize = (max[max.length - 1] - 0) * (10000000000000000000 - 0) / (10000000000000000000 - 0);
      stepSize = stepSize / 3 + 0;
      stepSize = Math.round(stepSize);
    }
    this.gradientChartOptionsConfigurationWithNumbersAndGrid = {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        bodySpacing: 4,
        mode: 'nearest',
        intersect: 0,
        position: 'nearest',
        xPadding: 10,
        yPadding: 10,
        caretPadding: 10,
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            ticks: {
              display: true,
              fontColor: '#AAAAAA',
              stepSize: stepSize
            },
            gridLines: {
              zeroLineColor: 'transparent',
              drawBorder: false,
              color: '#334966',
            },
          },
        ],
        xAxes: [
          {
            display: true,
            ticks: {
              display: true,
              fontColor: '#AAAAAA',
              maxRotation: 45,
              minRotation: 45
            },
            gridLines: {
              zeroLineColor: 'transparent',
              drawTicks: false,
              display: false,
              drawBorder: false,
              color: '',
            },
          },
        ],
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 15,
          bottom: 15,
        },
      },
    };
    this.canvas = document.getElementById('headerChart');
    this.ctx = this.canvas.getContext('2d');
    this.gradientStroke = this.ctx.createLinearGradient(255, 255, 255, 0);
    this.gradientStroke.addColorStop(0, '#ffffff');
    this.gradientStroke.addColorStop(1, this.chartColor);

    this.gradientFill = this.ctx.createLinearGradient(255, 255, 255, 50);
    this.gradientFill.addColorStop(0, 'rgba(255, 255, 255, 0)');
    this.gradientFill.addColorStop(1, 'rgba(255, 255, 255, 0.40)');
    this.myChart = new Chart(this.ctx, {
      type: 'line',
      data: {
        labels: this.invertArray(this.authService.isRole(RoleNames.Administrator) ?   this.adminDashboard.labels : this.vendorDashboard.labels) ,
        datasets: [
          {
            label: 'Orders',
            borderColor: '#FFFFFF',
            pointBorderColor: '#FFFFFF',
            pointBackgroundColor: '#FFFFFF',
            pointBorderWidth: 2,
            pointHoverRadius: 4,
            pointHoverBorderWidth: 1,
            pointRadius: 4,
            fill: true,
            backgroundColor: this.gradientFill,
            borderWidth: 2,
            data: this.authService.isRole(RoleNames.Administrator) ?   this.adminDashboard.dailyOrders  : this.vendorDashboard.dailyOrders
          }
        ]
      },
      options: this.gradientChartOptionsConfigurationWithNumbersAndGrid
    });
  }

  invertArray(array: any[]): any[] {
    // tslint:disable-next-line:no-shadowed-variable
    const newArray: any[] = [];
     for (let i = array.length - 1; i >= 0 ; i--) {
       newArray.push(array[i]);
     }
     return newArray;
   }
}
