import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent {
  message = '';
  viewAllText = '';
  viewAll: () => void;
  createAnother: () => void;
  constructor() { }
}
