export enum DataType {
  Number,
  Date,
}
export enum SendEmailType {
  Individual = 1,
  ToAllCustomer,
  ToAllMerchants,
  ToAllShops,
  ToAllUsers
}
