import { Injectable } from '@angular/core';
import * as signalR  from '@microsoft/signalr';
import { EndpointFactoryService } from './endpoint-factory.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { Sale } from '../models/sales';
import {ILogger, LogLevel} from '@microsoft/signalr';
import {Notification} from '../models/notification';
import { SoundService } from '../services/sound.service';

class CustomSignalRLogger implements ILogger {
  log(logLevel: LogLevel, message: string): void {}
}
@Injectable({
  providedIn: 'root',
})
export class SignalRService extends EndpointFactoryService {
  timerId: any = null;
  isConnected = false;
  isConnecting = false;
  private isListenersSet = false;
  hubConnection: signalR.HubConnection;
  private newSales = new Subject<Notification>();
  private newNotification = new Subject<Notification>();
  private readonly url = this.configurations.baseUrl + '/notificationHub';
  private option = {
    accessTokenFactory: () => this.accessToken
  };
  getNewSaleEvent(): Observable<Notification> {
    return this.newSales.asObservable();
  }
  getNewNotificationEvent(): Observable<Notification> {
    return this.newNotification.asObservable();
  }


//   init() {
//  console.log(this.url);
//     this.hubConnection = new signalR.HubConnectionBuilder()
//       .withUrl(this.url, this.option)
//       .configureLogging(new CustomSignalRLogger())
//       .withAutomaticReconnect([0, 0, 10000])
//       .build();
//     this.addListeners();
//   }

init() {
  const option = {
    accessTokenFactory: () => this.accessToken,
    UseDefaultCredentials : true
  };
  this.hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(this.url, option)
    .configureLogging(new CustomSignalRLogger())
    .build();
  this.addListeners();
}



  connect() {
    if (!this.accessToken) {
      return;
    }
    if (!this.isConnected) {
      this.hubConnection
        .start()
        .then(() => {
          if (this.isConnected) {
            this.disconnect();
          }
          console.log('Connected');
          this.isConnected = true;
        })
        .catch(err => {
          console.log('Disconnected');
          this.isConnected = false;
          this.reconnect();
        });
    }

  }

  player = new Audio();
  notificationSound() {
    this.player.src = '../../assets/sounds/notification.wav';
    this.player.play();
  }


  disconnect() {
    this.hubConnection.stop().then(() => (this.isConnected = false));
  }
  reconnect() {
    setTimeout(() => {
      this.connect();
    }, 500);
  }

  addListeners() {
    if (this.isListenersSet) {
      return;
    }
    this.isListenersSet = true;
    this.hubConnection.onclose(() => {
      console.log('Closed');
      this.isConnected = false;
      this.reconnect();
    });
    this.hubConnection.on('onNewSale', (sale: Notification) => {
      // this.notificationSound();
      this.newSales.next(sale);
    });
    this.hubConnection.on('showNotification', (notification: Notification) => {
     // alert(notification);
      this.notificationSound();
    // this.alertService.showSuccessMessage(notification.toString());
      this.newNotification.next(notification);
    });
  }
}