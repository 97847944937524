import { Injectable } from '@angular/core';
import {EndpointFactoryService} from '../../../services/endpoint-factory.service';
import {ChangePassword} from '../../../models/change-password';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginEndpointService extends EndpointFactoryService {
  private readonly _changePassword: string = '/api/v1/User/password';
  readonly updateFcmUrl: string = this.configurations.baseUrl + '/api/v1/User/fcm';
  get changePasswordUrl() { return this.configurations.baseUrl + this._changePassword; }


  getChangePasswordEndpoint(changePassword: ChangePassword): Observable<any> {
    const body = {
      previousPassword: changePassword.previousPassword,
      password: changePassword.password,
      confirmPassword: changePassword.confirmPassword,
    };
    return this.http.put(this.changePasswordUrl, body, { headers: this.getRequestHeaders().headers });
  }
  updateFcm(token: string) {
    
    return this.http.post(this.updateFcmUrl, {
      'fcmId': token,
    }, {headers: this.getRequestHeaders().headers});
    
  }
}
