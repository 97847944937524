import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {AuthService} from '../services/auth.service';
import {AlertService} from '../services/alert.service';
import {Router} from '@angular/router';
import {ErrorHandlerService} from '../services/error-handler.service';
import {from, Observable, of, Subject, throwError} from 'rxjs';
import {mergeMap, switchMap, catchError, map} from 'rxjs/operators';
import {LoginService} from '../pages/login/services/login.service';
import {ResponseErrorModel} from '../models/response-error-model';
import {ConfigurationService} from '../services/configuration.service';
import {empty} from 'rxjs/internal/Observer';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private alertService: AlertService,
    private router: Router,
    private loginService: LoginService,
    private errorHandler: ErrorHandlerService,
    private authService: AuthService) {
  }

  private isRefreshing = false;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (ConfigurationService.isUseFakeApi) {
      let response = new HttpResponse();
      response = response.clone({status: 200});
      return of(response);
    }
    if (!window.navigator.onLine) {
      return throwError(
        // in place of clone:
        new HttpErrorResponse({
          error: this.CustomNotOnlineResponse(),
        }),
      );
    }
    if (this.authService.isLoggedIn) {
      if (this.authService.isSessionExpired && !this.isRefreshing) {
        this.alertService.startLoadingMessage('Refreshing Session...');
        this.isRefreshing = true;
        return this.loginService.refreshToken(this.authService.refreshToken).pipe(
          mergeMap(data => {
            this.isRefreshing = false;
            this.authService.processRefreshToken(data);
            const clonedRequest = req.clone({
              headers: new HttpHeaders({
                'Authorization': 'Bearer ' + this.authService.accessToken,
                'Content-Type': 'application/json',
              })
            });
            return next.handle(clonedRequest)
              .pipe(map(event => {
                if (event instanceof HttpResponse) {
                  if (event.body && event.body.message && event.status >= 300) {
                    event = event.clone({body: this.resolveError(event.body)});
                  }
                }
                return event;
              }))
              .pipe(catchError((error: HttpErrorResponse) => {
                this.errorHandler.handleError(error);
                return throwError(error);
              }));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.authService.logout();
            this.errorHandler.handleError(err);
            return from([]);
          }));
      }
    }
    return next.handle(req)
      .pipe(map(event => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.message && event.status >= 300) {
            event = event.clone({body: this.resolveError(event.body)});
          }
        }
        return event;
      }))
      .pipe(catchError((error: HttpErrorResponse) => {
        this.errorHandler.handleError(error);
        return throwError(error);
      }));
  }

  private resolveError(body: any) {
    return {
      response: null,
      error: this.CustomMessageResponse(body.message)
    };
  }


  CustomMessageResponse(msg: string): ResponseErrorModel {
    const data: ResponseErrorModel = new ResponseErrorModel();
    data.errors = [];
    data.message = msg;
    return data;
  }

  CustomNotOnlineResponse(): ResponseErrorModel {
    const data: ResponseErrorModel = new ResponseErrorModel();
    data.message = 'Token Expired';
    return data;
  }

}
