import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'
import { EndpointFactoryService } from './endpoint-factory.service';
import { environment } from '../../environments/environment';
import { SoundService } from './sound.service';
@Injectable()
export class MessagingService {
  private readonly url = environment.baseUrl + '/api/v1/Notification';

  currentMessage = new BehaviorSubject(null);
  constructor(private angularFireMessaging: AngularFireMessaging,
              private soundService: SoundService
  )
   {

    this.init();
  }
  init() {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }
  get token() {
    return this.angularFireMessaging.requestToken;
  }
  deleteToken() {
    this.token.subscribe(   (token) => {
      this.angularFireMessaging.deleteToken(token).subscribe(res=> {
        //console.log('Token', res);
      });
    },
    (err) => {
      console.error('Unable to get permission to notify.', err);
      this.angularFireMessaging.deleteToken(err).subscribe(res=> {
        //console.log('Token',res);
      });
    });
  }
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
      //  console.log(token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log('new message received.', payload);
        this.currentMessage.next(payload);
        this.soundService.notificationSound();
      });
  }
}