export {}; // this file needs to be a module
declare global {
  interface Array<T> {
    remove(element: T): Array<T>;
  }
}

Array.prototype.remove = function <T>(this: T[], element: T): T[] {
  const index = this.indexOf(element, 0);
  if (index > -1) {
    this.splice(index, 1);
  }
  return this;
};

