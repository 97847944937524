import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-view-image-dialog',
  templateUrl: './view-image-dialog.component.html',
  styleUrls: ['./view-image-dialog.component.scss']
})
export class ViewImageDialogComponent implements OnInit {

  image: string;
  constructor(private dialog: NgbActiveModal) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialog.close();
  }

}
