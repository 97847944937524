import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewImageDialogComponent } from '../view-image-dialog/components/view-image-dialog.component';
import { environment } from '../../../environments/environment';
import { ImageFile } from '../../constants/image-file';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
  private _image: string = ImageFile.DefaultImage;
  get image() {
    if (!this._image.startsWith('data:image')) {
      if (this._image.startsWith('http')) {
        return this._image;
      } else {
        return this.host + this._image;
      }


    } else {
      return this._image;
    }
  }
  @Input() set image(value: string) {
    this._image = value;

  }

  @Input() viewImage = true;
  @Input() class: any;
  @Input() popupImage: string;
  @Input() height = 64;
  @Input() showDialog = true;

  host: string;
  constructor(private modal: NgbModal) {
    this.host = environment.baseUrl;
  }

  ngOnInit(): void {}

  showImage() {
    if (!this.showDialog) {
      return;
    }
    const modal = this.modal.open(ViewImageDialogComponent, {
      centered: true,
      backdrop: 'static',
    });
    const instance = modal.componentInstance as ViewImageDialogComponent;
    if (this.popupImage) {
      instance.image = this.popupImage;
    } else {
      instance.image = this.image;
    }
  }
}
