import { Injectable } from '@angular/core';
import { LocalStoreManager } from './local-store-manager.service';

import { Customer } from '../models/customer';
import { Dbkey } from './db-key';
import { Observable, Subject } from 'rxjs';
import { AlertService } from './alert.service';
import { JwtHelper } from './jwt-helper';
import { Utilities } from './utilities';
import { NavigationExtras, Router } from '@angular/router';
import { RoleNames } from '../constants/role-names';
import { unwrapFirst } from 'codelyzer/util/function';
import { isArray } from 'util';
import { ChangePassword } from '../models/change-password';
import { LoginResponse } from '../models/login-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  get currentUser(): Customer {
    if (this._currentUser == null) {
      this._currentUser = this.localStorage.getData(
        Dbkey.CURRENT_USER
      ) as Customer;
    }
    return this._currentUser;
  }

  private _currentUser: Customer;
  roles: string[];
  userId: string;
  employeeId: string;
  shopId: string;
  private _loginStatus = new Subject<boolean>();
  private previousIsLoggedInCheck = false;
  isVerified = false;

  get accessToken(): string {
    return this.localStorage.getData(Dbkey.ACCESS_TOKEN);
  }
  constructor(
    private localStorage: LocalStoreManager,
    private router: Router,
    private alertService: AlertService
  ) {
    this.initializeLoginStatus();
    this.processIsVerified();

  }
  private initializeLoginStatus() {
    this.localStorage.getInitEvent().subscribe(() => {
      this.reevaluateLoginStatus();
      this.processIsVerified();
    });

  }

  public reevaluateLoginStatus(currentUser?: Customer) {
    this._currentUser = currentUser || this.currentUser;
    const isLoggedIn = this._currentUser != null;
    // console.log("Current Users : " , currentUser)
    this.setRole();
    if (this.previousIsLoggedInCheck !== isLoggedIn) {
      setTimeout(() => {
        this._loginStatus.next(isLoggedIn);
      });
    }

    this.previousIsLoggedInCheck = isLoggedIn;

  }

  getLoginStatusEvent(): Observable<boolean> {
    return this._loginStatus.asObservable();
  }
  get accessTokenExpiryDate(): Date {
    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(Dbkey.TOKEN_EXPIRES_IN, true);
  }

  get refreshAccessTokenExpiryDate(): Date {
    this.reevaluateLoginStatus();
    return this.localStorage.getDataObject<Date>(
      Dbkey.REFRESH_TOKEN_EXPIRES_IN,
      true
    );
  }

  get isSessionExpired(): boolean {
    if (this.accessTokenExpiryDate == null) {
      return true;
    }

    return !(this.accessTokenExpiryDate.valueOf() > new Date().valueOf());
  }

  private setRole() {
    if (this.currentUser == null) {
      return;
    }
    this.roles = [];
    if (isArray(this.currentUser.role)) {

      this.roles.push(...this.currentUser.role);
    } else {
      this.roles.push(this.currentUser.role);
    }
  }
  private saveUserDetails(
    user: Customer,
    accessToken: string,
    refreshToken: string,
    expiresIn: Date,
    rememberMe: boolean
  ) {
    if (rememberMe) {
      this.localStorage.savePermanentData(accessToken, Dbkey.ACCESS_TOKEN);
      this.localStorage.savePermanentData(expiresIn, Dbkey.TOKEN_EXPIRES_IN);
      this.localStorage.savePermanentData(refreshToken, Dbkey.REFRESH_TOKEN);
      this.localStorage.savePermanentData(user, Dbkey.CURRENT_USER);
    } else {
      this.localStorage.saveSyncedSessionData(accessToken, Dbkey.ACCESS_TOKEN);
      this.localStorage.saveSyncedSessionData(
        expiresIn,
        Dbkey.TOKEN_EXPIRES_IN
      );
      this.localStorage.saveSyncedSessionData(
        refreshToken,
        Dbkey.REFRESH_TOKEN
      );
      this.localStorage.saveSyncedSessionData(user, Dbkey.CURRENT_USER);
    }
  }

  saveUser(user: Customer) {
    this.localStorage.savePermanentData(user, Dbkey.CURRENT_USER);
  }

  logout(): void {
    this.localStorage.deleteData(Dbkey.ACCESS_TOKEN);
    this.localStorage.deleteData(Dbkey.ID_TOKEN);
    this.localStorage.deleteData(Dbkey.REFRESH_TOKEN);
    this.localStorage.deleteData(Dbkey.TOKEN_EXPIRES_IN);
    this.localStorage.deleteData(Dbkey.USER_PERMISSIONS);
    this.localStorage.deleteData(Dbkey.CURRENT_USER);
    this.roles = [];
    this.alertService.resetStickyMessage();
    this._currentUser = null;
    this.reevaluateLoginStatus();
    this.redirectLoggedInUser();
  }

  get idToken(): string {
    this.reevaluateLoginStatus();
    return this.localStorage.getData(Dbkey.ID_TOKEN);
  }

  get refreshToken(): string {
    this.reevaluateLoginStatus();
    return this.localStorage.getData(Dbkey.REFRESH_TOKEN);
  }

  get isLoggedIn(): boolean {
    return this.currentUser != null;
  }

  get rememberMe(): boolean {
    return this.localStorage.getDataObject<boolean>(Dbkey.REMEMBER_ME) === true;
  }

  processLogin(result: LoginResponse): Customer {
    if (result.access_token == null) {
      throw new Error('Received token was empty');
    }
    // const expiresIn = response.expires_in;
    const jwtHelper = new JwtHelper();
    const info = jwtHelper.decodeToken(result.id_token) as Customer;

    info.isVerified = jwtHelper.decodeToken(result.id_token)['isVerified'] === 'True';

    const tokenExpiryDate = new Date(0);
    tokenExpiryDate.setUTCSeconds(info.exp);
    this.saveUserDetails(
      info as Customer,
      result.access_token,
      result.refresh_token,
      tokenExpiryDate,
      true
    );
    this.reevaluateLoginStatus(info as Customer);
    const user = info as Customer;
    this.roles = [];
    if (isArray(user.role)) {
      this.roles.push(...user.role);
    } else {
      this.roles.push(user.role);
    }
    this.processIsVerified();
    return info as Customer;
  }

  processRefreshToken(result: LoginResponse): Customer {
    if (result.access_token == null) {
      throw new Error('Received token was empty');
    }

    // const expiresIn = response.expires_in;
    const jwtHelper = new JwtHelper();
    const info = jwtHelper.decodeToken(result.id_token);
    const tokenExpiryDate = new Date(0);
    tokenExpiryDate.setUTCSeconds(info.exp);
    this.saveUserDetails(
      info as Customer,
      result.access_token,
      this.refreshToken,
      tokenExpiryDate,
      true
    );
    this.reevaluateLoginStatus(info as Customer);
    const user = info as Customer;
    this.roles = [];
    if (isArray(user.role)) {
      this.roles.push(...user.role);
    } else {
      this.roles.push(user.role);
    }
    return info as Customer;
  }

  redirectLoggedInUser() {
    this.reevaluateLoginStatus();
    if (!this.isLoggedIn) {
      this.router.navigateByUrl('/login');
      return;
    }
    let redirect = '/';
    if (redirect === '/' && this.isRole(RoleNames.Administrator)) {
      redirect = '/dashboard/home';
    }
    if (redirect === '/' && this.isRole(RoleNames.Merchant)) {
      if (this.isVerified) {
        redirect = '/dashboard/home-merch';
      } else {
        redirect = '/dashboard/home-merch';
      }
    }
    const urlParamsAndFragment = Utilities.splitInTwo(redirect, '#');
    const urlAndParams = Utilities.splitInTwo(
      urlParamsAndFragment.firstPart,
      '?'
    );

    const navigationExtras: NavigationExtras = {
      fragment: urlParamsAndFragment.secondPart,
      queryParams: Utilities.getQueryParamsFromString(urlAndParams.secondPart),
      queryParamsHandling: 'merge',
    };

    this.router.navigate([urlAndParams.firstPart], navigationExtras);
  }

  isRole(role: any): boolean {
    if (!this.roles || this.roles.length === 0) {

      this.reevaluateLoginStatus();
    }
    if (isArray(role)) {
      return this.roles.some((p) => role.indexOf(p) > -1);
    }
    return this.roles.some((p) => p === role);
  }

  public saveUserDetailsOnly(user: Customer) {
    this.localStorage.saveSyncedSessionData(user, Dbkey.CURRENT_USER);
  }

  processIsVerified() {

    if (this.currentUser) {
      this.isVerified = this.currentUser.isVerified;
    } else {
      if (this._currentUser) {
          this.isVerified = this.currentUser.isVerified;
      }
    }
  }
}
