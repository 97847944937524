import {Component, OnInit} from '@angular/core';
import {SignalRService} from '../../services/signal-r.service';
import Timeout = NodeJS.Timeout;
import {Router} from '@angular/router';
import {CurrencyPipe} from '@angular/common';
import { MessagingService } from '../../services/notification.service';
@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.scss']
})
export class NotificationPanelComponent implements OnInit {

  constructor(private router: Router,
              private messagingService: MessagingService) {
  }
  isNewEvent = false;
  message = 'No New Event has occurred yet';
  timeOutFunc: Timeout = null;

  reloadPage() {
    if (this.router.url === '/dashboard/sales') {
    } else {
      this.router.navigateByUrl('/dashboard/sales');
    }
  }
  ngOnInit(): void {
    this.messagingService.currentMessage.subscribe(result => {
      if (result) {
        if (this.timeOutFunc) {
          clearTimeout(this.timeOutFunc);
          this.timeOutFunc = null;
        }
        this.isNewEvent = true;
        this.message = result.notification.body;
        this.timeOutFunc = setTimeout(() => {
          this.isNewEvent = false;
          this.message = 'No New Event has occurred yet';
        }, 10000);
      }
    });

  }

}
