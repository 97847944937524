import {Component, OnDestroy, OnInit} from '@angular/core';
import {AlertDialog, AlertMessage, AlertService, MessageSeverity} from './services/alert.service';
import {AuthService} from './services/auth.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DialogComponent} from './dialog/dialog/dialog.component';
import {Subscription} from 'rxjs';
import {SignalRService} from './services/signal-r.service';
import {  MessagingService } from './services/notification.service';
declare var $: any;
import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, OnInit {

  subscription: Subscription[] = [];

  constructor(private alertService: AlertService,
              private modalService: NgbModal,
              private signalRService: SignalRService,
              private authService: AuthService,
              private messagingService: MessagingService) {
    this.subscription[0] = this.alertService.getDialogEvent().subscribe(alert => this.showDialog(alert));
    this.subscription[1] = this.alertService.getMessageEvent().subscribe(message => this.showToast(message, false));
    this.subscription[2] = this.alertService.getStickyMessageEvent().subscribe(message => this.showToast(message, true));

    // this.toastyConfig.theme = 'bootstrap';
    this.init();
  }

  ngOnInit() {
    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.messagingService.currentMessage.subscribe(res => {
      // console.log(res);
    });
    
  }
// initSignalR(){
//   setTimeout(() => {
//     console.log('sleep');
 
//   }, 5000);
// }


  init() {
    this.authService.getLoginStatusEvent().subscribe(result => {
      if (!result) {
        this.signalRService.disconnect();
        this.signalRService.isConnected = false;
        this.modalService.dismissAll();
        this.alertService.showMessage('Success', 'User has logout Successfully', MessageSeverity.info);
      } else {

        this.signalRService.init();
        this.signalRService.isConnected = false;
        this.signalRService.connect();
      }
    });
  }
  showDialog(dialog: AlertDialog) {
    if (dialog === undefined) {
      this.modalService.dismissAll();
      return;
    }
    dialog.okLabel = dialog.okLabel || 'OK';
    dialog.cancelLabel = dialog.cancelLabel || 'Cancel';
    const modal = this.modalService.open(DialogComponent, {
      centered: true,
      backdrop: 'static',
      size: 'sm'
    });
    const instance = modal.componentInstance as DialogComponent;
    instance.initDialog(dialog);
    instance.NoClick = () => {
      modal.close();
    };
  }
  showToast(message: AlertMessage, isSticky: boolean) {
    let delay = 0;
    if (message === undefined) {
      $.notifyClose();
      return;
    }
    if (message.severity === MessageSeverity.wait) {
      delay = 0;
    } else {
      delay = 3000;
    }
    $.notifyDefaults({
      allow_dismiss: true,
      z_index: 1051
    });

    $.notify({
      icon: 'notifications',
      message: message.detail,
      title: message.summary,
    }, {
      type: this.getClassBySeverity(message.severity),
      delay: delay,
      z_index: 1051,
      placement: {
        from: 'top',
        align: 'right'
      },
      template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
        '<button type="button" aria-hidden="true" class="close" data-notify="dismiss" style="margin:5px">×</button>' +
        '<span style"color:#FFFFFF !important;" data-notify="icon"></span> ' +
        '<span style"color:#FFFFFF !important;" data-notify="title">{1}</span> ' +
        '<span style"color:#FFFFFF !important;" data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  getClassBySeverity(severity: MessageSeverity): string {
    switch (severity) {
      case MessageSeverity.success:
        return 'success';
      case MessageSeverity.error:
        return 'danger';
      case MessageSeverity.warn:
        return 'warn';
      case MessageSeverity.info:
        return 'info';
      case MessageSeverity.wait:
        return 'info';
    }
    return '';
  }

  ngOnDestroy(): void {
    this.subscription.forEach(p => p.unsubscribe());
  }

}
