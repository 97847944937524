import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  player = new Audio();
  constructor() { }
  notificationSound() {
    this.player.src = '../../assets/sounds/notification.wav';
    this.player.play();
  }
}
