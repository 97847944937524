import {AbstractControl, ValidatorFn, Validators} from '@angular/forms';
import {AppRegex} from '../constants/app-regex';
import {DataType} from '../enums/data-type.enum';

export class CustomValidator {
  private static regex = new RegExp(AppRegex.PasswordRegex);
  private static timingRegex = new RegExp(AppRegex.timingsRegex);

  static emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'email': true};
      }
      return null;
    };
  }

  static swiftCodeValidator(min: number, max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'swiftCode': true};
      }
      if ((control.value.length !== min && control.value.length !== max)) {
        return {'swiftCode': true};
      }
      return null;
    };
  }

  static PasswordMatch(passwordControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'match': true};
      }
      if ((control.value !== passwordControl.value)) {
        return {'match': true};
      }
      return null;
    };
  }

  static Password(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = this.regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : { 'password': true };
    };
    // const result = Validators.pattern(AppRegex.PasswordRegex);
    // return result.length ? null : { 'password': true };
  }

  static GreaterThan(minControl: AbstractControl, type: DataType = DataType.Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (minControl == null || control == null) {
        return null;
      }
      if (type === DataType.Number) {
        if ((+control.value <= +minControl.value)) {
          return {'greater': true, 'value': minControl.value};
        }
      }
      if (type === DataType.Date) {
        if ((control.value <= minControl.value)) {
          return {'greater': true, 'value': minControl.value};
        }
      }

      return null;
    };
  }

  static LessThan(maxControl: AbstractControl, type: DataType = DataType.Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if (maxControl == null || control == null) {
        return null;
      }
      if (type === DataType.Number) {
        if ((+control.value > +maxControl.value)) {
          return {'less': true, 'value': maxControl.value};
        }
      }
      if (type === DataType.Date) {
        if ((control.value > maxControl.value)) {
          return {'less': true, 'value': maxControl.value};
        }
      }

      return null;
    };
  }

  static NoUnderScore(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'underscore': true};
      }
      if ((control.value.toString().includes('_'))) {
        return {'underscore': true};
      }
      return null;
    };
  }

  static Phone(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (!control.value) {
        return null;
      }
      const value = control.value.toString();
      if (!(value.startsWith('+') && value.length >= 10 && value.length <= 15)) {
        return {'phone': true};
      }
      return null;
    };
  }
  static Required(type: DataType = DataType.Number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (type === DataType.Number) {
        if ((+control.value <= 0)) {
          return {'required': true};
        }
      }
      if (type === DataType.Date) {
        const d1 = Date.parse(control.value);
        const today = new Date();
        if ((d1 <= +today)) {
          return {'required': true};
        }
      }

      return null;
    };
  }

  static Pin(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'pin': true};
      }
      if ((control.value + '').length !== 4) {
        return {'pin': true};
      }
      return null;
    };
    // return Validators.pattern(AppRegex.PasswordRegex);
  }



  static Timing(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = this.timingRegex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : { 'timing': true };
    };
    // const result = Validators.pattern(AppRegex.PasswordRegex);
    // return result.length ? null : { 'password': true };
  }
  static TimingMatch(timingControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {

      if (control.value === undefined || control.value == null) {
        return {'match': true};
      }
      if ((control.value !== timingControl.value)) {
        return {'match': true};
      }
      return null;
    };
  }


}
