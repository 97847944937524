import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  aspectRation = 9 / 6;
  public changesSavedCallback: (croppedImage: any) => void;
  public changesCancelledCallback: () => void;
  constructor() { }
  init(event: any, aspectRation?: number)  {
    if (aspectRation !== undefined) {
      this.aspectRation = aspectRation;
    }
    this.imageChangedEvent = event;
  }
  ngOnInit(): void {
  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
}
imageLoaded() {
    /* show cropper */
}
cropperReady() {
    /* cropper ready */
}
loadImageFailed() {
    /* show message */
}
  save() {
    this.changesSavedCallback(this.croppedImage);
  }

  cancel() {
    this.changesCancelledCallback();
  }
}
