// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  hmr: false,
  // baseUrl: 'https://c947-2400-adc5-1c3-2800-4414-84a8-f670-e77f.ngrok.io/herbtech',
  baseUrl: 'https://backend.herbtechkiosk.com',
  // baseUrl: 'https://api.dev.herbtechkiosk.com',
  publishKey: '',
  firebase: {
    apiKey: 'AIzaSyBUIjb0VyAPra2bohyBtSFdbUYdPjXAkyE',
    authDomain: 'herbtech-29500.firebaseapp.com',
    projectId: 'herbtech-29500',
    storageBucket: 'herbtech-29500.appspot.com',
    messagingSenderId: '617468123752',
    appId: '1:617468123752:web:01b4a8986b0737942139dd',
    measurementId: 'G-2587JDCVSN'
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
 